/* eslint react/prop-types: 0 */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';

class Card extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isExpanded: false };
		this.cardButton = React.createRef();
	}
	focus(scroll = false) {
		this.cardButton.current.focus();

		if (scroll) {
			this.cardButton.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		}
	}
	render() {
		return (
			<button
				ref={this.cardButton}
				id={this.props.id}
				onClick={() => this.focus()}
				className="m-2 group w-full md:w-1/6 md:flex-grow p-2 md:focus:w-1/2 shadow-lg rounded overflow-hidden"
			>
				<div className="-m-2 -mt-4 text-justify">
					{this.props.image}
					<div className="px-4 py-2 mt-2">
						<div className="text-primary font-medium text-xl">{this.props.heading}</div>
					</div>
					<div className="px-4 py-2 ">
						<div className="text-gray-700 text-sm text-justify">{this.props.content}</div>
					</div>
				</div>
				<div className="block group-focus:hidden px-4 py-2 w-full text-justify">
					<div className="text-primary">mehr ...</div>
				</div>
				<div className="hidden group-focus:block px-4 py-2">
					<div className="text-gray-700 text-sm text-justify">{this.props.moreContent}</div>
				</div>
			</button>
		);
	}
}

const h2class = 'font-medium mb-4';

function Behandlungen({ data }) {
	let trainingstherapie = React.createRef();
	let manualtherapie = React.createRef();
	return (
		<Layout>
			<SEO
				keywords={[
					`Physiotherapie`,
					`Wien`,
					`1010`,
					`movendos`,
					`Manualtherapie`,
					`Manuelle Therapie`
				]}
				title="Behandlungen"
			/>
			<section id="behandlungenActive-1" className="flex flex-col items-center md:flex-row my-4 mb-8">
				<div className="md:w-3/4 md:mr-8">
					<blockquote className="pl-4 font-serif leading-loose text-left border-l-4 border-gray-900">
						Unser Ziel ist es, sowohl passiv als auch aktiv, den Körper in Bewegung zu bringen.
					</blockquote>

					<cite className="block mt-4 text-xs font-medium text-right uppercase">– Das movendos Team</cite>
				</div>
				<figure className="hidden md:block md:w-1/4">
					<Img
						className="rounded-full shadow-lg"
						fluid={data.hantel.childImageSharp.fluid}
						alt="Trainingstherapie mit Kurzhantel"
					/>
				</figure>
			</section>
			<section id="behandlungenActive-2">
				<div className="text-primary font-medium text-xl mb-2">Physiotherapie</div>
				<div>
					<p>
						Die Physiotherapie ist ein wichtiger Bestandteil des Gesundheitswesen mit dem Fokus auf die
						Wiederherstellung der Bewegungsfähigkeit. Das Tätigkeitsfeld ist sehr breit gefächert. Es reicht
						von der Prävention bis zur Rehabilitation mit dem Ziel, Funktionsstörungen zu behandeln und das
						natürliche Bewegungsverhalten wieder herzustellen. Je nach Beschwerdebild oder Verletzung,
						kommen verschiedene Therapiemaßnahmen zum Einsatz.
					</p>
					<p>
						Eine fundierte Befunderhebung und Untersuchung ist eine wichtige Voraussetzung, um einen
						individuellen Behandlungsplan zu erstellen. Egal, ob nach einer Verletzung oder, Operation – wir
						nehmen uns die Zeit, Sie bestmöglichst zu behandeln, damit Sie wieder fit für den Alltag und
						Sport werden.
					</p>
					<p>
						Unser Therapieangebot umfasst{' '}
						<a
							className="text-primary"
							href="#manualtherapie"
							onClick={(e) => {
								e.preventDefault();
								manualtherapie.current.focus(true);
							}}
						>
							Manualtherapie
						</a>,{' '}
						<a
							className="text-primary"
							href="#trainingstherapie"
							onClick={(e) => {
								e.preventDefault();
								trainingstherapie.current.focus(true);
							}}
						>
							Trainingstherapie
						</a>, Taping, Faszientechniken, Triggerpunktbehandlungen, Flossing, uvm.
					</p>
				</div>
			</section>
			<div className="flex flex-wrap mb-4 mt-20 items-start">
				<Card
					id="manualtherapie"
					ref={manualtherapie}
					image={
						<Img
							className="h-0 pb-1/3"
							fluid={data.manualtherapie.childImageSharp.fluid}
							alt="Manualtherapie"
						/>
					}
					heading="Manuelle Therapie"
					content={<h2 className={h2class}>&bdquo;Schmerzen verstehen, begründen und beseitigen!&rdquo;</h2>}
					moreContent={
						<div>
							<p>
								Die Manualtherapie gehört zu den ältesten konservativen Behandlungen und hat im Laufe
								der Zeit einen großen Wandel erlebt. So hat sich die Therapieform aus dem klassischen
								Altertum von der reinen mechanischen Wirkung seit dem 20. Jahrhundert in seiner
								Wirkungsweise und Ganzheitlichkeit erweitert. Es wurde erkannt, dass ohne Beachtung von
								Nervenschädigungen, muskulären Dysbalancen und psychosozialen Faktoren eine
								ursachenorientierte Therapie nicht möglich ist.
							</p>
							<p>
								Gegenwärtig werden sowohl manuelle Techniken, als auch Muskelkräftigung und -aktivierung
								eingesetzt. Durch das Lösen von Blockaden, Engpässen und erhöhten Spannungen, kann eine
								Schmerzlinderung erreicht werden und ein natürlicher Heilungsprozess gefördert werden.
								Durch eine angemessene Kräftigung und Verbesserung koordinativer Abläufe, werden Fehl-
								und Überbelastungen reduziert. Es entsteht ein präventiver und rehabilitativer Aufbau
								des Körpers.
							</p>
						</div>
					}
				/>
				<Card
					id="trainingstherapie"
					ref={trainingstherapie}
					image={
						<Img
							className="h-0 pb-1/3"
							fluid={data.trainingstherapie.childImageSharp.fluid}
							alt="Trainingstherapie mit Langhantel"
						/>
					}
					heading="Trainingstherapie"
					content={<h2 className={h2class}>&bdquo;Wer sich bewegt, bringt etwas in Bewegung!&rdquo;</h2>}
					moreContent={`Die Trainingstherapie orientiert sich an der Belastbarkeit der beeinträchtigten Strukturen.
					 Sie ist ein wesentlicher Bestandteil bei Sportverletzungen,  Überlastungssyndromen im Sport oder nach Operationen. Durch angemessene Übungen werden Bewegungsabläufe trainiert, um den Anforderungen der jeweiligen Sportart standhalten zu können. Kraft-, Ausdauer- und Koordinationstraining führen wieder zur gewohnten Leistungsfähigkeit oder sogar darüber hinaus.`}
				/>
			</div>
			<br />
			<br />
		</Layout>
	);
}

export const query = graphql`
	{
		hantel: file(relativePath: { eq: "therapie-image.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 672) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		physiotherapie: file(relativePath: { eq: "physios_14.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 672) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		osteopathie: file(relativePath: { eq: "physios_16.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 672) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		manualtherapie: file(relativePath: { eq: "physios_18.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 672) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		trainingstherapie: file(relativePath: { eq: "therapie-content-2.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 672) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default Behandlungen;
